<template>

  <div id="app">
<navbar msg="test"/>
  
  
 <TextImg class="header" :content="component3"  :columnConfig="columnConfig3"/>

    <TextImg :content="component1" class="roundpic" :columnConfig="columnConfig1"/>


    <section class="section">
    <div class="container">
      <h1>Meine Unternehmen:</h1>
<div class="columns is-multiline">
     <flipcard :flipcardcontent="flipcard1"/>
     <flipcard :flipcardcontent="flipcard2"/>
     <flipcard :flipcardcontent="flipcard3"/>
     <flipcard :flipcardcontent="flipcard4"/>   
    <flipcard :flipcardcontent="flipcard5"/>
     <flipcard :flipcardcontent="flipcard6"/>
     <flipcard :flipcardcontent="flipcard7"/>
     <flipcard :flipcardcontent="flipcard8"/> 
    <flipcard :flipcardcontent="flipcard9"/>
     <flipcard :flipcardcontent="flipcard10"/>  
</div>
</div>
     </section>
        
 <TextImg :content="component2"  :columnConfig="columnConfig2"/>


      <CoolLightBox :items="items" :index="index" @close="index = null"> </CoolLightBox>
      <h1 class="section container">Fotografie</h1>
    <div class="images-wrapper section container">
      
      <div class="image"  v-for="(image, imageIndex) in items" :key="imageIndex" @click="index = imageIndex" :style="{ backgroundImage: 'url(' + image + ')' }"></div>
    </div>       


  </div>


  
</template>



<script>
import TextImg from './components/TextImg.vue'
import navbar from './components/navbar.vue'
import flipcard from './components/flipcard.vue'


export default {
  name: 'App',
  components: {
    TextImg,
    navbar,
    flipcard
  },
    data: function () {
      
    return {
      items: [
        require ('./assets/img/teaser_hund1.jpg'),
        require ('./assets/img/teaser_hund2.jpg'),
        require ('./assets/img/teaser_hund3.jpg'),
        require ('./assets/img/teaser_pferd8.jpg'),
        require ('./assets/img/teaser_pferd9.jpg'),
         require ('./assets/img/teaser_pferd10.jpg'),
        require ('./assets/img/teaser_pferd-hund.jpg'),
        require ('./assets/img/teaser_richy.jpg'),
        require ('./assets/img/teaser_richy2.jpg'),
        require ('./assets/img/teaser_richy3.jpg'),
         require ('./assets/img/teaser_training1.jpg'),
         require ('./assets/img/teaser_training2.jpg'),
         require ('./assets/img/teaser_training3.jpg'),
         require ('./assets/img/teaser_training4.jpg'),
         require ('./assets/img/teaser_training5.jpg'),
         require ('./assets/img/teaser_training6.jpg'),
         require ('./assets/img/teaser_training7.jpg'),
         require ('./assets/img/teaser_training8.jpg'),
         require ('./assets/img/teaser_training9.jpg'),
         require ('./assets/img/teaser_training10.jpg'),
         require ('./assets/img/teaser_training11.jpg'),
         require ('./assets/img/teaser_training12.jpg'),
        require ('./assets/img/teaser_traktor1.jpg'),
        require ('./assets/img/teaser_traktor2.jpg'),
        require ('./assets/img/teaser_traktor3.jpg'),
        require ('./assets/img/teaser_traktor4.jpg'),
        require ('./assets/img/teaser_traktor5.jpg'),
        require ('./assets/img/teaser_traktor6.jpg'),
        require ('./assets/img/teaser_traktor7.jpg'),
        require ('./assets/img/teaser_traktor8.jpg'),
      ],
      index: null,

      component1: {
      headline: "FitnessFarmerKing",
      img: require ('./assets/img/erwinrichardkoch.jpg'),
      msg:"FitnessFarmerKing® Hinter diesem Synonym steckt Richy KOCH – die Themen Fitness, Fotografie, Landwirtschaft, Pferde und Immobilien, sind seine großen Leidenschaften. Angefangen hat alles mit einem Fitnessstudio, wodurch er seine Leidenschaft auch zum Beruf machen konnte, mittlerweile haben sich diese ein wenig vermehrt. Auch im Online-Bereich hat sich der FitnessFarmerKing verwirklicht – es gibt Trainingspläne, Fitnessblogs und Fitnessprodukte für alle Sportbegeisterten unter euch. Die Liebe zur Landwirtschaft ist bereits im Kindesalter durch den elterlichen Betrieb entstanden und nach und nach haben es sich, neben zwei Hunden und fünf Katzen, auch noch zahlreiche Pferde und der eine oder andere Traktor auf dem Hof gemütlich gemacht, der langsam und stetig wächst.",
      },

      component2: {
        headline: "",
        img: "",
         msg:"Den Bereich Fotografie lebt Richy gerne im Fitnessstudio aus, wo sowohl Mitarbeiter als auch Trainierende professionell abgelichtet werden. Doch nicht nur dort, auch die Hofbewohner werden aus verschiedensten Blickwinkeln fotografiert und gefilmt. In liebevoller Detailarbeit werden diese Aufnahmen dann bearbeitet, sodass tolle Videos und Bilder entstehen. Zu guter Letzt gibt es auch noch einige Immobilienflächen, die Richy sein Eigen nennt und teilweise zur Vermietung anbietet. Also egal ob Fragen zum Training, Fitnessprodukten, zur Landwirtschaft/Pferdewirtschaft oder doch zu seinen Immobilien auftauchen – melde dich unter hello@fitnessfarmerking.eu"
      }, 
            component3: {
        headline: "",
        img: require ('./assets/img/header.jpg'),


         msg:"Den Bereich Fotografie lebt Richy gerne im Fitnessstudio aus, wo sowohl Mitarbeiter als auch Trainierende professionell abgelichtet werden. Doch nicht nur dort, auch die Hofbewohner werden aus verschiedensten Blickwinkeln fotografiert und gefilmt. In liebevoller Detailarbeit werden diese Aufnahmen dann bearbeitet, sodass tolle Videos und Bilder entstehen. Zu guter Letzt gibt es auch noch einige Immobilienflächen, die Richy sein Eigen nennt und teilweise zur Vermietung anbietet. Also egal ob Fragen zum Training, Fitnessprodukten, zur Landwirtschaft/Pferdewirtschaft oder doch zu seinen Immobilien auftauchen – melde dich unter hello@fitnessfarmerking.eu"
      },

      columnConfig1: {
        column1: 7,
        column2: 5,
      },

       columnConfig2: {
        column1: 12,
      },

             columnConfig3: {
        column2: 12,
      },
flipcard1: {
  headline: "INJOY Leibnitz",
   img: require ('./assets/img/teaser_240x200_injoyleibnitz.jpg'),
   facebook: "https://www.facebook.com/INJOY.Leibnitz",
   weblink: "https://www.injoy-leibnitz.at/",
   instagram: "https://www.instagram.com/injoy_leibnitz/"
},
flipcard2: {
  headline: "INJOY Deutschlandsberg",
   img: require ('./assets/img/teaser_240x200_injoydeutschlandsberg.jpg'),
   facebook: "https://www.facebook.com/INJOY.Deutschlandsberg",
   weblink: "https://www.injoy-deutschlandsberg.at/",
   instagram: "https://www.instagram.com/injoy_deutschlandsberg/"
},
flipcard3: {
  headline: "INJOY Feldbach",
   img: require ('./assets/img/teaser_240x200_injoyfeldbach.jpg'),
    facebook: "https://www.facebook.com/INJOY.Feldbach",
   weblink: "https://www.injoy-feldbach.at/",
   instagram: "https://www.instagram.com/injoy_feldbach/",

},
flipcard4: {
  headline: "INJOY Süd",
   img: require ('./assets/img/teaser_240x200_injoy.jpg'),
    facebook: "https://www.facebook.com/INJOY.studios.sued",
   weblink: "https://www.injoy-sued.at/",
   instagram: "https://www.instagram.com/injoy_sued/"
},
flipcard5: {
  headline: "Fitness Farmer King®",
   img: require ('./assets/img/teaser_240x200_richykochheader.jpg'),
    facebook: "https://www.facebook.com/FitnessFarmerKing",
    instagram: "https://www.instagram.com/fitnessfarmerking/",
    youtube: "https://www.youtube.com/channel/UCQRXHQDhU2Dz48Muz0AvC4g",
    tiktok: "https://www.tiktok.com/@fitnessfarmerking?",
    tumblr: "https://fitnessfarmerking.tumblr.com/",
},
flipcard6: {
  headline: "Body Queen®",
   img: require ('./assets/img/teaser_240x200_bodyqueen.jpg'),
    facebook: "https://www.facebook.com/BodyQueenEU",
   weblink: "https://www.body-queen.eu/",
   instagram: "https://www.instagram.com/body_queen/",
},
flipcard7: {
  headline: "Iron King®",
   img: require ('./assets/img/teaser_240x200_ironking.jpg'),
    facebook: "https://www.facebook.com/IronKingEU",
   weblink: "https://www.iron-king.eu/",
   instagram: "https://www.instagram.com/iron_king_body/",
},
flipcard8: {
  headline: "PK Immobilien",
   img: require ('./assets/img/teaser_240x200_pkimmobilien.jpg'),
    facebook: "https://www.facebook.com/pkimmobiliengmbh",
  
},
flipcard9: {
  headline: "Caballos PRE Fleur de lis",
   img: require ('./assets/img/teaser_240x200_prefleurdelis.jpg'),
    facebook: "https://www.facebook.com/PRE.fleurdelis",
      weblink: "https://spanischespferd.at/",

},
flipcard10: {
  headline: "Richy Koch",
   img: require ('./assets/img/teaser_240x200_richykoch.jpg'),
    facebook: "https://www.facebook.com/richy.iron/",
    instagram: "https://www.instagram.com/richy_koch/",
      twitter: "https://twitter.com/ErwinRichardKO1",
    linkedin: "https://www.linkedin.com/in/erwin-richard-koch-100a66124/",
},
    }
     },
}

</script>

<style>



@import './assets/css/style.css';
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;300;500;700&display=swap');


body {margin: 0;}

.images-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.images-wrapper .image {
    cursor: pointer;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
}


.images-wrapper .image {
    margin-bottom: 20px; 
    width: calc(33.333% - 20px);
    margin-right: 20px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5em;
  font-size: 1.4em;
  margin-top: 60px;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.image {border-radius: 10px;}


@media (max-width: 900px) {

  .images-wrapper .image {
    margin-bottom: 20px;
    width: 100%;
    margin-right: 0;}
  
}

</style>
