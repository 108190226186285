<template>
<div class="column flip-card-width">
  <div class="flip-card" :class="{ active: flipcardInner }" @click="flipcardInner = !flipcardInner">
  <div class="flip-card-inner">
    <div class="flip-card-front" :style="`background: linear-gradient(0deg, rgba(0, 60, 101, .8), rgba(0, 60, 101, .4)), url(${flipcardcontent.img})`">
      <h1>{{flipcardcontent.headline}}</h1>
    </div>
    <div class="flip-card-back"> 
      <div class="links">
       <a :href="`${flipcardcontent.facebook}`"> <font-awesome-icon :icon="[ 'fab', 'facebook' ]" /></a>
       <a   v-if="flipcardcontent.instagram" :href="`${flipcardcontent.instagram}`"> <font-awesome-icon :icon="[ 'fab', 'instagram' ]" /></a>
          <a   v-if="flipcardcontent.tiktok" :href="`${flipcardcontent.tiktok}`"> <font-awesome-icon :icon="[ 'fab', 'tiktok' ]" /></a>
        <a   v-if="flipcardcontent.linkedin" :href="`${flipcardcontent.linkedin}`"> <font-awesome-icon :icon="[ 'fab', 'linkedin' ]" /></a>
       <a   v-if="flipcardcontent.tumblr" :href="`${flipcardcontent.tumblr}`"> <font-awesome-icon :icon="[ 'fab', 'tumblr' ]" /></a>
        <a   v-if="flipcardcontent.youtube" :href="`${flipcardcontent.youtube}`"> <font-awesome-icon :icon="[ 'fab', 'youtube' ]" /></a>
        <a   v-if="flipcardcontent.twitter" :href="`${flipcardcontent.twitter}`"> <font-awesome-icon :icon="[ 'fab', 'twitter' ]" /></a>
       <a v-if="flipcardcontent.weblink" :href="`${flipcardcontent.weblink}`"> <font-awesome-icon icon="globe" /></a>
       </div>
    </div>
  </div>
</div>
</div>
</template>


<script>
export default {
  name: 'flipcard',
  props: {
    flipcardcontent: Object,
    },
  data: function () {
    return {
    flipcardInner: false
    }
  },
  methods: {
    countup() {
      this.count++
    },
    newheadline() {
      this.headline= "SUCK IT";
    }
  }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h1 {font-size: 1em;}

a {color: #fff; margin: 5px;}

.flip-card-width {width: 17.85%;}

.flip-card {
  border-radius: 10px;
  background-color: transparent;
  width: 100%;
  height: 200px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}



/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container  */


/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover !important;
 background-position: 50%;
  background-repeat: no-repeat;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  border-radius: 10px;
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  border-radius: 10px;
  background-color: rgba(0, 60, 101, 1);
  color: white;
  transform: rotateY(180deg);
}

@media (min-width: 671px) {
  .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
}

@media  (max-width: 1200px) {
  .flip-card-width {width: 21.85%;}
  
}

@media  (max-width: 800px) {
  .flip-card-width {width: 45.85%;}
  
}

@media  (max-width: 670px) {
  .flip-card-width {width: 98.85%;}

  .flip-card.active .flip-card-inner {
  transform: rotateY(180deg);
}
  
}
</style>
