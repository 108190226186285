<template>
  <nav class="navbar">
    <div class="container">
       <figure><img src="../assets/img/logo.svg"></figure>
       </div>
  </nav>
</template>


<script>
export default {
  name: 'navbar',
  props: {
    msg: String,
    headline: String,
    text: String,
},
  data: function () {
    return {
      count: 2
    }
  },
  methods: {
    countup() {
      this.count++
    },
    newheadline() {
      this.headline= "SUCK IT";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



.container {
height: 100%;
width: auto;
}

figure {
  width: auto; 
  height: 100%; 
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

figure img {width: auto; height: 100%; }

.navbar {width: 100%; background-color: #111; height: 60px; position: fixed; top: 0; z-index: 100; padding: 5px;}
</style>
