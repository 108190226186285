<template>
  <section class="section">
    <div class="container">
    <div class="columns">
    <div v-if="columnConfig.column1" :class="`column is-${columnConfig.column1}`">
       <h1>{{content.headline}}</h1>
       <p>{{content.msg}}</p>
      </div>
          <div v-if="columnConfig.column2" :class="`column is-${columnConfig.column2}`">
       <figure><img :src="`${content.img}`"></figure>
      </div>
       </div>
       </div>
  </section>
</template>


<script>
export default {
  name: 'TextImg',
  props: {
    content: Object,
    columnConfig: Object},
  data: function () {
    return {
      count: 2
    }
  },
  methods: {
    countup() {
      this.count++
    },
    newheadline() {
      this.headline= "SUCK IT";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



@media (max-width: 900px) {

  .columns {flex-direction: column-reverse}
  
}

.roundpic img {border-radius: 50%;}

figure {margin: 0;}

</style>
